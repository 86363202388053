import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCheckSquare } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as IconFileEarmarkFill } from 'bootstrap-icons/icons/file-earmark-fill.svg'
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'
import Textarea from "react-textarea-autosize";
import ReactTooltip from 'react-tooltip'
import SignatureCanvas from 'react-signature-canvas'
import { Link, useLocation } from '@reach/router'

import useUpload from "hooks/useUpload";
import Resource from './Resource'
import Consumer from './Consumer'
import Vademecum from '../Vademecum/Vademecum'
import Treatment from './Treatment'
import useStore from './useStore'
import getDate from 'lib/getDate'
import useQuery from "hooks/useQuery";
import useMutation from 'hooks/useMutation'

let {
  REACT_APP_API_URL,
  REACT_APP_PRESCRIPTION_FRONT_URL,
} = process.env

const clone = window.structuredClone || JSON.clone

function Prescription(props) {
  let upload = useUpload()
  let _location = useLocation()
  let [preview, setPreview] = useState()
  let $canvas = useRef(null)
  let $logo = useRef(null)
  let { t } = useTranslation();
  let [type, setType] = useState(props.type || 'prescription')
  let [whatsapp, setWhatsApp] = useState({})
  let [email, setEmail] = useStore(state => [state.email, state.setEmail])
  let [size, setSize] = useStore(state => [state.size, state.setSize])
  let [copies, setCopies] = useStore(state => [state.copies, state.setCopies])
  let [response, setResponse] = useState()
  let [current, setCurrent] = useState([])
  let [showCurrentTreatments, setShowCurrentTreatments] = useState(false)
  let [showCertificateForm, setShowCertificateForm] = useState(false)
  let [showCanvasSignature, setShowCanvasSignature] = useState(false)
  let [signature, setSignature] = useState({ base64: '' })
  let [certificate, setCertificate] = useState({})
  let [logo, setLogo] = useState({ flag: false })
  let [consumer, setConsumer] = useState()
  let [resource, setResource] = useState({})
  let [warnings, setWarnings] = useState({})
  let [mutate, mutation] = useMutation(`prescriptions/${window._l('').substr(1)}`);
  let [notifyByEmail, notifyByEmailMutation] = useMutation(`prescriptions/email`);
  let [saveLogo, saveLogoMutation] = useMutation(`prescriptions/${window._l('').substr(1)}/logos`);
  let [financier, setFinancier] = useState({})
  let [specialty, setSpecialty] = useState({})
  let [license, setLicense] = useState({})
  let [location, setLocation] = useState({})
  let [showConsumerForm, setShowConsumerForm] = useState()
  let [consumerWarnings, setConsumerWarnings] = useState()
  let [resourceWarnings, setResourceWarnings] = useState()

  let [state, setState] = useState({
    indications: "",
    content: "",
    date: getDate(),
    treatments: [],
  })

  const query = useQuery(
    window._l(`/${consumer?.id}/treatments`).substr(1),
    {
      enabled: !!consumer?.id,
      onSuccess: items => {
        if (!Array.isArray(items)) return setCurrent([])
        let array = {}
        for (let item of items) {
          if (!/^records\//.test(item.id)) continue
          if (item.deleted) continue
          if (!item.label && !item.product && !item.drug) continue
          let product_code = item.product_code || `custom:${item.drug || item.label || item.product}`
          if (!product_code) continue
          if (array[product_code]) continue
          if (state.treatments?.some(t => t.product_code === product_code && !t.optional)) continue
          let payload = {
            product_code,
            product: item.product,
            label: item.label,
            drugs: item.drugs || [],
            presentation: item.presentation,
            drug: item.drug,
            company: item.company,
            quantity: 1,
            optional: true,
            isLong: false,
            noSubstitute: false,
            generic: false,
          }
          array[product_code] = payload
        }
        array = clone(Object.values(array))
        setCurrent(array)
      }
    }
  );

  let logos = useQuery(`prescriptions/${window._l('').substr(1)}/logos`, {
    onSuccess: data => {
      if (!Array.isArray(data)) return
      let logo = [...data].reverse().at(0)
      if (!logo?.url) return

      setLogo({ ...logo, flag: true })
    }
  })

  const onChangeFinancier = financier => {
    setFinancier(financier)
  }

  let onSaveConsumer = consumer => {
    setConsumer(clone(consumer))
    if (showConsumerForm) setShowConsumerForm(false)
  }

  const onChange = ({ target: { name, value } }) => {
    state[name] = value
    setState(clone(state))
  }

  const onClose = () => {
    if (!consumerWarnings?.flag && showConsumerForm) return setShowConsumerForm(false)
    if (props.onClose) return props.onClose()
  }

  const onChangeTreatment = treatment => {
    if (treatment.remove) {
      state.treatments = state.treatments.filter(t => t.product_code !== treatment.product_code)
    } else if (treatment.add) {
      if (!state.treatments.some(t => t.product_code === treatment.product_code)) {
        state.treatments.push({ ...treatment, add: undefined, added: true })
      }
    } else {
      for (let i = 0; i < state.treatments.length; i += 1) {
        let item = state.treatments[i]
        if (item.product_code !== treatment.product_code) continue
        state.treatments[i] = { ...item, ...treatment }
      }
    }
    setState(clone(state))
  }

  const onVademecumChange = treatments => {
    if (!treatments) return

    let treatment = treatments[0]
    if (!treatment) return

    if (!Array.isArray(state.treatments)) {
      state.treatments = []
    }

    if (state.treatments.some(t => t.product_code === treatment.product_code)) return

    state.treatments.push(treatment)
    setState(clone(state))
  }

  let onChangeLicense = license => {
    setLicense(license)
  }

  let onChangeLocation = location => {
    setLocation(location)
  }

  let onChangeSpecialty = specialty => {
    setSpecialty(specialty)
  }

  let getSignatureKey = () => {
    let uid = props.user?.uid
    if (!uid) throw new Error('missing_uid')

    return ['ehr/signature', uid].filter(Boolean).join(':')
  }

  let getCertificateKey = () => {
    let uid = props.user?.uid
    if (!uid) throw new Error('missing_uid')

    return ['ehr/certificate', uid].filter(Boolean).join(':')
  }

  let storeCertificate = certificate => {
    try {
      let storeKey = getCertificateKey()
      let payload = JSON.stringify({
        name: certificate?.name,
        base64: certificate?.base64,
        type: certificate?.type,
      })
      window.localStorage.setItem(storeKey, payload)
    } catch (e) {
      console.error(e)
    }
  }

  let loadCertificate = () => {
    try {
      let storeKey = getCertificateKey()
      let json = window.localStorage.getItem(storeKey)
      let certificate = JSON.parse(json)
      if (certificate && certificate.base64 && certificate.name && certificate.type) {
        setCertificate(certificate)
        setShowCertificateForm(true)
      }

    } catch (e) {
      console.error(e)
    }
  }

  let loadSignature = () => {
    try {
      let storeKey = getSignatureKey()
      let base64 = window.localStorage.getItem(storeKey)
      if (base64 && $canvas.current) {
        setShowCanvasSignature(true)
        $canvas.current.fromDataURL(base64, { ratio: 1, width: 630, height: 150 })
      }
      if (base64) setSignature({ base64, canvas: 'hide' })
      return base64
    } catch (e) {
      console.error(e)
    }
  }

  let onChangeLogo = async ({ target: { name, value, type, files } }) => {
    let payload = clone(logo)
    console.log(logo)
    let file = files?.[0]
    if (type === 'file' && file) {
      setLogo({ ...logo, uploading: true })
      let links = await upload(files)
      let url = links?.[0]
      if (url) {
        payload.url = url
        await saveLogo({ url, resourceID: resource?.id })
      }

    } else if (type === 'checkbox') {
      payload[name] = !payload[name]
    } else {
      payload[name] = value
    }

    if (name === 'flag' && payload.flag && !payload.url) try {
      $logo.current.click()
    } catch (e) {
      console.log(e)
    }

    setLogo(payload)
  }

  let onClearLogo = () => {
    $logo.current.value = ''
    setLogo({ ...logo, url: undefined })
  }

  const onChangeCertificate = async ({ target: { name, value, type, files } }) => {
    let payload = clone(certificate)
    let file = files?.[0]
    if (type === 'file' && file) {
      payload.name = file.name
      const { type, base64 } = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        // reader.onload = () => resolve(reader.result.replace('data:application/x-pkcs12;base64,',''))
        reader.onload = () => {
          try {
            let [type, base64] = reader.result.split(';')
            base64 = base64.replace('base64,','')
            type = type.replace('data:', '')
            return resolve({ type, base64 })
          } catch {
            resolve({})
          }
        }
      })
      payload.base64 = base64
      payload.type = type
      storeCertificate(payload)
    } else if (type === 'checkbox') {
      payload[name] = !payload[name]
    } else {
      payload[name] = value
    }

    setCertificate(payload)
  }

  let onUpdateSignature = () => {
    try {
      let base64 = $canvas.current.toDataURL()
      let key = getSignatureKey()
      setSignature({ ...signature, base64 })
      window.localStorage.setItem(key, base64)
    } catch {}
  }

  let onClearSignature = () => {
    try {
      $canvas.current.clear()
      setSignature({ canvas: 'show' })
    } catch (e) {}
    try {
      let key = getSignatureKey()
      window.localStorage.removeItem(key)
    } catch {}
  }

  let makePayload = () => {
    let payload = {
      qrEndpoint: REACT_APP_PRESCRIPTION_FRONT_URL,
      size,
      copies: parseInt(copies) || 1,
      signature: showCanvasSignature ? signature?.base64 : '',
      date: state.date,
      indications: state.indications,
      content: state.content,
      diagnostic: state.diagnostic || '',
      certificate: showCertificateForm && certificate?.name ? certificate : undefined,
      tags: state.tags,
      logo: logo?.flag && logo?.url ? logo.url : undefined
    }

    if (state.treatments.length > 0) {
      payload.treatments = state.treatments
    }

    if (resource?.id) {
      let address = location?.address || ''
      let emails = resource?.emails?.map(e => e.value)?.filter(Boolean) || []
      if (Array.isArray(location?.emails)) {
        emails.push(...location.emails)
      }
      payload.resource = {
        id: resource.id,
        label: resource.label,
        address,
        emails,
        phones: location?.phones || [],
        specialty: specialty?.id ? t(specialty.id, specialty.label) : '',
      }
    }

    if (payload.resource && license?.value) {
      payload.resource.license = {
        number: license.value,
        kind: license.kind,
        jurisdiction: license.jurisdiction
      }
    }

    if (consumer?.id) {
      payload.consumer = {
        id: consumer.id,
        label: consumer.label,
        identification: {
          value: consumer.identification,
        },
        dob: consumer.dob,
        gender: consumer.gender,
        emails: [email.address].filter(Boolean)
      }
    }

    if (payload.consumer && financier?.id) {
      let [name, ...rest] = financier.id?.replace('pms_financiers:', '').split('/').filter(Boolean)
      let plan = rest.join('')
      if (plan === '*') {
        plan = ''
      }
      payload.consumer.financier = {
        key: financier.id || financier.key,
        label: financier.label,
        plan: financier.plan || plan || '',
        identification: financier.identification
      }
    }
    return payload
  }

  let getPreview = async () => {
    if (!_location.search.includes('preview')) return
    let payload = makePayload()
    if (!payload.resource?.id || !payload.consumer?.id) return setPreview()
    if (!payload.resource?.license?.number) return setPreview()

    if (!payload.content && warnings.indications && (!payload.treatments || payload.treatments.length <= 0)) return setPreview()

    let buffer = await fetch(`${REACT_APP_API_URL}/prescriptions/preview`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(res => res.blob())

    let blob = new Blob([buffer], { type: 'application/pdf' });
    setPreview(window.URL.createObjectURL(blob))
  }

  let sendEmail = async () => {
    let payload = {
      resource: { emails: [], label: resource.label },
      consumer: { emails: [email.address].filter(Boolean), label: consumer.label },
      ...response
    }
    if (email.cc1 && Array.isArray(resource?.emails) && resource.emails.length > 0) {
      payload.resource.emails = resource.emails?.map(e => e.value)?.filter(Boolean) || []
    }
    if (email.cc2 && Array.isArray(location?.emails) && location.emails.length > 0) {
      payload.resource.emails.push(...location.emails)
    }

    notifyByEmail(payload, {
      onMutate: () => {
        setEmail({ ...email, status: 'sending' })
      },
      onSuccess: () => {
        setEmail({ ...email, status: 'sent' })
      }
    })
  }

  let reset = (type, flag) => {
    setType(type || 'prescription')
    setEmail({ ...email, status: undefined })
    setResponse()
    if (!flag) setState({
      indications: "",
      content: "",
      date: getDate(),
      treatments: [],
    })
  }

  const save = () => {
    setResponse()
    if (response?.pdf && !response?.status?.code) return

    let payload = makePayload()

    mutate(payload, {
      onSuccess: response => {
        let options = { pdf: response.pdf }
        options.consumer = consumer?.label?.split(',')?.reverse()?.at(0)?.trim()
        options.resource = resource?.label?.split(', ')?.reverse()?.join(' ')
        let text = t('ehr:prescription_whatsapp_message', `Hola ${options.consumer},\nTe paso el link con la receta.\n${options.resource}\n${options.pdf}`, options)
        let params = new URLSearchParams({ text })
        params = params.toString()
        setResponse({ ...response })
        setWhatsApp({ ...whatsapp, params })
        if (props.onSave) props.onSave()
      }
    })
  }

  let isInvalidFinancier = consumerWarnings?.financiers && consumerWarnings?.financiers[financier?.id]?.flag;

  useEffect(() => {
    try {
      if (props.user?.uid) loadCertificate()
    } catch {}
  }, [props.user])

  useEffect(() => {
    try {
      if (props.user?.uid) loadSignature()
    } catch {}
  }, [props.user])

  useEffect(() => {
    if (consumer && consumer.id) try {
      let address = consumer.emails.find(email => email.value)?.value || ''
      let number = consumer.phones.find(phone => phone.value)?.value || ''
      setEmail({ address, status: undefined })
      setWhatsApp({ number })
    } catch {}
  }, [consumer])

  useEffect(() => {
    try {
      let warnings = {}
      if (type === 'prescription') {
        warnings.treatments = state.treatments.length <= 0
        warnings.indications = state.indications.trim().length <= 7
      } else if (type !== 'prescription') {
        warnings.content = state.content.replace(/\s+/g, '').length <= 2
      }
      setWarnings(payload => ({ ...payload, ...warnings }))
    } catch (e) {
      console.error(e)
    }
  }, [type, state])

  useEffect(() => {
    if (props.consumer?.id) setConsumer(clone(props.consumer))
  }, [props.consumer])

  useEffect(() => {
    setResource(clone(props.resource))
  }, [props.resource])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    getPreview()
  }, [_location.search, consumer, email, whatsapp, resource, state, signature, license, financier, specialty, location, copies, showCanvasSignature, certificate, showCertificateForm])

  let _treatments = (state.treatments || []).filter(t => !t.optional || !showCurrentTreatments)

  if (resourceWarnings?.flag) return (
    <div className={props.className || ''}>
      <div style={{ maxWidth: '680px' }} className="d-flex flex-fill flex-column gap-2 bg-white rounded my-3 mx-auto p-3">
        <div className="bg-warning p-2 rounded">
          {t('ehr:prescription_warnings_resource_license_or_address_missing', 'Pare realizar una receta debe completar matricula del profesiona y las direccion del consultorio', { context: 'form' })}
        </div>
        <div className="d-flex align-items-center gap-3">
          {(resourceWarnings?.license) && (
            <Link onClick={() => onClose()} to={window._l(`/setup/${resource?.id}/docs`)}>{t('ehr:prescription_warnings_link_to_docs_resource_setup', 'Matriculas')}</Link>
          )}
          {(resourceWarnings?.location) && (
            <Link onClick={() => onClose()} to={window._l(`/setup/${resource?.id}/ranges`)}>{t('ehr:prescription_warnings_link_to_locations_resource_setup', 'Consultorios')}</Link>
          )}
          {(resourceWarnings?.specialty) && (
            <Link onClick={() => onClose()} to={window._l(`/setup/${resource?.id}/practices`)}>{t('ehr:prescription_warnings_link_to_services_resource_setup', 'Especialidades')}</Link>
          )}
          <div className="flex-fill"></div>
          <button className="btn btn-sm text-secondary" onClick={onClose}>{t('ehr:prescription_warnings_resources_setup_later', 'Mas tarde')}</button>
        </div>
      </div>
    </div>
  )

  return showConsumerForm || consumerWarnings?.flag || isInvalidFinancier || (consumer && !consumer.id) ? (
    <div className={props.className || ''}>
      <div style={{ maxWidth: '680px' }} className="d-flex flex-fill flex-column gap-2 bg-white rounded my-3 mx-auto p-3">
        {consumerWarnings && (consumerWarnings.identification || consumerWarnings?.financiers?.flag || consumerWarnings?.firstName || consumerWarnings?.lastName) && (
          <div className="bg-warning p-2 rounded">
            {t('ehr:prescription_warnings_missing_consumer_info', 'Para realizar una receta son necesarios datos minimos del paciente, como: Nombre, Apellido, DNI. En caso que no sea por particular y tiene una cobertura medica tambien es necesario indicar el nro. de afiliado. A continuacion marcamos los datos que faltan', { context: 'form' })}
          </div>
        )}
        <Consumer.Form
          modal={false}
          consumerID={consumer.id}
          initialState={consumer}
          onClose={() => onClose()}
          onSave={onSaveConsumer}
          remove={false}
          ehr={false}
          events={false}
          warnings={{
            identification: consumerWarnings?.identification,
            identification_length: consumerWarnings?.identification_length,
            financiers: consumerWarnings?.financiers,
            firstName: consumerWarnings?.firstName,
            lastName: consumerWarnings?.lastName,
          }}
          source="prescription"
        />
      </div>
    </div>
  ) : response && response.pdf && !response.status?.code ? (
    <div className={props.className || ''}>
      <div className="d-flex justify-content-center gap-2 mt-3">
        <div style={{ maxWidth: '680px' }} className="bg-white rounded over p-3">

          <h5 className=" fw-light text-center mb-3">
            {t('ehr:prescription_created_title', 'Receta Creada')}
          </h5>

          <a href={response?.pdf} rel="noopener noreferrer" target="_blank" className="btn bg-light text-primary border text-start mb-3">
            <div>{t('ehr:open_or_download_pdf', 'Abrir o descargar receta en una nueva pestaña', { context: 'form' })}</div>
            <small className="text-secondary">{t('ehr:open_pdf_hint', 'Por la manera que funcionan los navegadores en algunos casos el PDF se abre directamente en el navegador y en otras se solicita descargar')}</small>
          </a>

          <div className="d-flex justify-content-between gap-2 mb-3">
            <div className="flex-fill w-100">
              <div className="d-flex align-items-center gap-1 form-control form-control-sm">
                <label htmlFor="prescriptionEmailAddress" className="text-secondary">{t('ehr:email_address', 'Email:', { context: 'form' })}</label>
                <input
                  disabled={!!email.status}
                  id="prescriptionEmailAddress"
                  type="text"
                  className="border-0 flex-fill px-0 focus-0"
                  value={email.address || ''}
                  onChange={e => setEmail({ ...email, address: e.target.value })}
                />
                <button className={`btn btn-sm p-0 ${email.status === 'sent' ? 'border-0 text-success' : 'btn-link'}`} onClick={sendEmail} disabled={!!email.status}>
                  {email.status === 'sent' ? (
                    t('ehr:sending', 'Enviado', { context: 'form' })
                  ) : email.status === 'sending' ? (
                    t('ehr:sending', 'Enviando', { context: 'form' })
                  ) : (
                    t('ehr:send', 'Enviar', { context: 'form' })
                  )}
                </button>
              </div>
              {Array.isArray(resource?.emails) && resource.emails.length > 0 && (
                <div className="btn btn-sm bg-light text-truncate text-start" data-tip={t('ehr:prescription_cc1_tip', `Enviarme copia. ${resource.emails.map(e => e.value).join(', ')}`, { emails: resource.emails.map(e => e.value).join(', ') })}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={!!email.cc1}
                    id="prescriptionCC1"
                    onChange={() => setEmail({ ...email, cc1: !email.cc1 })}
                  />
                  <label className="form-check-label ms-1" htmlFor="prescriptionCC1">
                    {t('ehr:prescription_form_email_cc1', 'Copia')}
                  </label>
                </div>

              )}
              {Array.isArray(location?.emails) && location.emails.length > 0 && (
                <div className="btn btn-sm bg-light text-truncate text-start" data-tip={t('ehr:prescription_cc2_tip', `Enviar copia al consultorio. ${location.emails.join(', ')}`, { emails: location.emails.join(', ') })}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={!!email.cc2}
                    id="prescriptionCC2"
                    onChange={() => setEmail({ ...email, cc2: !email.cc2 })}
                  />
                  <label className="form-check-label ms-1" htmlFor="prescriptionCC2">
                    {t('ehr:prescription_form_email_cc2', 'Copia consultorio')}
                  </label>
                </div>
              )}
            </div>
            <div className="flex-fill w-100">
              <div className="d-flex align-items-center gap-1 form-control form-control-sm">
                <label htmlFor="prescriptionWhatsapp" className="text-secondary">{t('ehr:whatsapp_phone_number', 'WhatsApp:')}</label>
                <input
                  id="prescriptionWhatsapp"
                  type="text"
                  className="border-0 flex-fill px-0 focus-0"
                  value={whatsapp.number || ''}
                  onChange={e => setWhatsApp({ ...whatsapp, number: e.target.value })}
                />
                <a className="btn btn-sm btn-link p-0" target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${whatsapp.number}&${whatsapp.params || ''}`}>
                  {t('ehr:send', 'Enviar', { context: 'form' })}
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button className="btn btn-sm link-primary" onClick={() => reset(type || 'prescription', 'edit')}>{t('ehr:edit_prescription', 'Editar')}</button>
              <button className="btn btn-sm link-primary" onClick={() => reset('prescription')}>{t('ehr:new_prescription', 'Nueva Receta')}</button>
              <button className="btn btn-sm link-primary" onClick={() => reset('order')}>{t('ehr:new_order', 'Nueva Orden')}</button>
            </div>

            <button className="btn btn-sm link-secondary" onClick={onClose}>{t('close')}</button>
          </div>

        </div>
      </div>
    </div>
  ) : (
    <div className={props.className || ''}>
      <div className="d-flex justify-content-center align-items-center align-items-center gap-2 mt-3">
        <div style={{ maxWidth: '680px' }} className="d-flex flex-fill flex-column gap-2 bg-white rounded over p-3">
          
          <Resource
            resource={resource}
            location={location}
            license={license}
            onChangeLicense={onChangeLicense}
            onChangeLocation={onChangeLocation}
            onChangeSpecialty={onChangeSpecialty}
            onWarnings={setResourceWarnings}
          />
          {!consumer && (
            <Consumer.Selector
              onChange={consumer => setConsumer(clone(consumer))}
              warnings={warnings}
            />
          )}
          <Consumer
            consumer={consumer}
            financier={financier}
            onChangeFinancier={onChangeFinancier}
            onClickEdit={() => setShowConsumerForm(true)}
            onWarnings={setConsumerWarnings}
          />
          <div>
            <input
              disabled={false}
              placeholder={t('ehr:prescriptions_form_placeholder_date', 'Fecha')}
              className="form-control"
              value={state.date || ''}
              onChange={onChange}
              name="date"
              type="date"
            />
          </div>
          {type === 'prescription' ? (
            <>
              <div className="position-relative">
                <Vademecum
                  placeholder={t('ehr:prescription_form_placeholder_vademecum', 'Buscador de tratamientos')}
                  onChange={onVademecumChange}
                  onChangeReset
                />
                {current.length > 0 && (
                  <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
                    <div className="text-truncate btn btn-sm p-0 btn-link">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={!!showCurrentTreatments}
                        id="showCurrentTreatments"
                        name="showCurrentTreatments"
                        onChange={() => setShowCurrentTreatments(!showCurrentTreatments)}
                      />
                      <label className="form-check-label ms-1" htmlFor="showCurrentTreatments">
                        {t('ehr:prescription_form_show_ehr_treatments_label', 'Mostrar los tratamientos de HCE')} <sup className="text-secondary">{current.length}</sup>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {_treatments.length > 0 && (
                <div>
                  {_treatments.map(treatment =>
                    <Treatment
                      treatment={treatment}
                      onChange={onChangeTreatment}
                      key={treatment.product_code}
                    />
                  )}
                </div>
              )}
              {showCurrentTreatments && current.length > 0 && (
                <div>
                  {current.map(treatment =>
                    <Treatment
                      treatment={{
                        ...treatment,
                        ...state.treatments.find(t => t.product_code === treatment.product_code)
                      }}
                      onChange={onChangeTreatment}
                      key={treatment.product_code}
                    />
                  )}
                </div>
              )}

              <div>
                <Textarea
                  disabled={false}
                  placeholder={t('ehr:prescriptions_form_placeholder_diagnostic', 'Diagnostico (opcional)')}
                  className="form-control"
                  value={state.diagnostic || ''}
                  onChange={onChange}
                  name="diagnostic"
                  minRows="1"
                />
              </div>

              <div>
                <Textarea
                  disabled={false}
                  placeholder={t('ehr:prescriptions_form_placeholder_indications', 'Indicaciones (opcional)')}
                  className="form-control"
                  value={state.indications || ''}
                  onChange={onChange}
                  name="indications"
                  minRows="1"
                />
              </div>

              <div>
                <Textarea
                  disabled={false}
                  placeholder={t('ehr:prescriptions_form_placeholder_content', 'Comentarios (opcional)')}
                  className="form-control"
                  value={state.content || ''}
                  onChange={onChange}
                  name="content"
                  minRows="1"
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <Textarea
                  disabled={false}
                  placeholder={t('ehr:prescriptions_form_placeholder_content_order', 'Descripcion de la orden o estudio')}
                  className="form-control"
                  value={state.content || ''}
                  onChange={onChange}
                  name="content"
                  minRows="4"
                />
              </div>
            </>
          )}

          <div className="p-2 border rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="btn bg-light text-truncate text-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!!showCanvasSignature}
                  id="prescriptionCanvasSignature"
                  onChange={() => setShowCanvasSignature(!showCanvasSignature)}
                />
                <label className="form-check-label ms-1" htmlFor="prescriptionCanvasSignature">
                  {t('ehr:prescription_form_canvas_signature_label', 'Firma manuscrita')}
                </label>
              </div>
              {signature.canvas === 'hide' && !!signature.base64 && (
                <div className="flex-fill">
                  <img src={signature.base64} alt="" height="30" className="d-block" />
                </div>
              )}
              {showCanvasSignature && signature.canvas !== 'hide' ? (
                <button className="btn btn-sm text-secondary" onClick={onClearSignature}>{t('ehr:prescription_form_clear_canvas_signature_1', 'Borrar firma')}</button>
              ) : showCanvasSignature && signature.canvas === 'hide' && (
                <>
                  <button className="btn btn-sm text-secondary" onClick={onClearSignature}>
                    {t('ehr:prescription_form_clear_canvas_signature_2', 'Cambiar firma')}
                 </button>
                </>
              )}
            </div>
            <div className="position-relative">
              {!signature?.base64 && showCanvasSignature && (
                <div className="bg-light mt-2 rounded" style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '150px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontSize: '35px',
                  fontWeight: '200',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>{t('ehr:prescription_form_canvas_signature_hint', 'Dibuje su firma aqui')}</div>
              )}
              <SignatureCanvas
                onEnd={onUpdateSignature}
                onBegin={onUpdateSignature}
                penColor="blue"
                maxWidth={4}
                minWidth={2.5}
                canvasProps={{
                  width: 630,
                  height: 150,
                  className: showCanvasSignature && signature.canvas !== 'hide' ? `mt-2 rounded position-relative ${signature ? 'bg-light' : ''}`: 'd-none'
                }}
                ref={$canvas}
              />
            </div>
          </div>

          <div className="p-2 border rounded">
            <div className="d-flex gap-2 align-items-center">
              <div className="btn bg-light text-truncate">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!!showCertificateForm}
                  id="prescriptionCertificateSignature"
                  onChange={() => setShowCertificateForm(!showCertificateForm)}
                />
                <label className="form-check-label ms-1" htmlFor="prescriptionCertificateSignature">
                  {t('ehr:prescription_form_certificate_signature_label', 'Firma digital')}
                </label>
              </div>

              {showCertificateForm && (
                <>
                  <div>
                    {certificate.name ? (
                      <label className="btn bg-light text-truncate" style={{ maxWidth: '180px' }} htmlFor="certificateFile">
                        <IconFileEarmarkFill className="text-secondary" /> <strong>{certificate.name}</strong>
                      </label>
                    ) : (
                      <label className="btn bg-light text-truncate" style={{ maxWidth: '180px' }} htmlFor="certificateFile">
                        {t('ehr:prescription_form_certificate_signature_select_file', 'Seleccionar certificado')}
                      </label>
                    )}
                    <input
                      type="file"
                      name="file"
                      className="form-control"
                      id="certificateFile"
                      onChange={onChangeCertificate}
                      accept=".p12,.pem,.crt,.cert,.cer,.x509,application/x-pkcs12"
                    />
                  </div>

                  <div
                    className="flex-fill position-relative"
                    data-tip={t('ehr:prescription_form_certificate_signature_empty_phrase_message', 'Si el certificado no tiene clave deje en blanco el campo clave')}
                  >
                    <input
                      className="form-control"
                      type="text"
                      name="phrase"
                      id="certificatePhrase"
                      value={certificate.phrase || ''}
                      onChange={onChangeCertificate}
                      placeholder={t('ehr:prescription_form_certificate_signature_phrase_label', 'Clave del certificado')}
                    />
                    <div
                      className="position-absolute text-secondary"
                      style={{ top: 5, right: 8 }}
                    >
                      <IconInfoCircle width="14" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="p-2 border rounded">
            <div className="d-flex gap-2 align-items-center">
              <div className="btn bg-light text-truncate" data-tip={t('ehr:prescription_logo_tip', `Logo debe ser apaisado, es decir debe ser mas largo que ancho. La resolucion minima recomendada es de 800x286. El ratio del ancho sobre el largo es de 2.8, es decir el largo debe ser 2.8 veces el ancho. Ejemplo de dimensiones validas
800x286
1024x365
2048x731`)}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!!logo.flag}
                  id="prescriptionLogoFlag"
                  name="flag"
                  onChange={onChangeLogo}
                />
                <label className="form-check-label ms-1" htmlFor="prescriptionLogoFlag">
                  {t('ehr:prescription_form_logo_label', 'Incluir logo')}
                </label>
              </div>
              {logo.flag && (
                <>
                  {logo.uploading ? (
                    <label className="btn text-truncate text-secondary" style={{ maxWidth: '180px' }}>
                      {t('ehr:prescription_form_logo_uploading', 'Aguarde...')}
                    </label>
                  ) : logo.url ? (
                    <label className="btn bg-light text-truncate" style={{ maxWidth: '180px' }} htmlFor="logoFile">
                      {t('ehr:prescription_form_logo_change_file', 'Cambiar logo')}
                    </label>
                  ) : (
                    <label className="btn bg-light text-truncate" style={{ maxWidth: '180px' }} htmlFor="logoFile">
                      {t('ehr:prescription_form_logo_select_file', 'Seleccionar logo')}
                    </label>
                  )}
                </>
              )}

              <div>
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  id="logoFile"
                  ref={$logo}
                  onChange={onChangeLogo}
                  accept=".jpg,.jpeg,.png"
                />
              </div>

              {logo.url && (
                <div className="flex-fill d-flex justify-content-between">
                  <div>
                    <img src={logo.url} alt="" height="30" className="d-block" />
                  </div>
                  {$logo.current?.files?.[0] && (
                    <div>
                       <button className="btn btn-sm text-secondary" onClick={onClearLogo}>
                         {t('ehr:prescription_form_clear_logo', 'Borrar logo')}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex align-items center gap-2">
            <div className="btn btn-sm bg-light border text-secondary">
              <input
                className="form-check-input"
                type="radio"
                value=""
                checked={parseInt(copies) === 1}
                id="prescriptionCopies1"
                onChange={() => setCopies(1)}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionCopies1">
                {t('ehr:prescription_copies_1', 'Sin copia')}
              </label>
            </div>

            <div className="btn btn-sm bg-light border text-secondary">
              <input
                className="form-check-input"
                type="radio"
                value=""
                checked={parseInt(copies) === 2}
                id="prescriptionCopies2"
                onChange={() => setCopies(2)}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionCopies2">
                {t('ehr:prescription_copies_2', 'Duplicado')}
              </label>
            </div>
            <div className="btn btn-sm bg-light border text-secondary">
              <input
                className="form-check-input"
                type="radio"
                value=""
                checked={parseInt(copies) === 3}
                id="prescriptionCopies3"
                onChange={() => setCopies(3)}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionCopies3">
                {t('ehr:prescription_copies_3', 'Triplicado')}
              </label>
            </div>
            <div className="flex-fill"></div>

            <div className="btn btn-sm bg-light border text-secondary" data-tip={t('ehr:prescription_size_A4_tip', 'Tamañano de impresion hoja A4')}>
              <input
                className="form-check-input"
                type="radio"
                value=""
                checked={size === 'A4'}
                id="prescriptionSizeA4"
                onChange={() => setSize('A4')}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionSizeA4">
                {t('ehr:prescription_size_A4', 'A4')}
              </label>
            </div>

            <div className="btn btn-sm bg-light border text-secondary" data-tip={t('ehr:prescription_size_A5_tip', 'Tamañano de impresion hoja A5')}>
              <input
                className="form-check-input"
                type="radio"
                value=""
                checked={size === 'A5'}
                id="prescriptionSizeA5"
                onChange={() => setSize('A5')}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionSizeA5">
                {t('ehr:prescription_size_A5', 'A5')}
              </label>
            </div>

            <div className="btn btn-sm bg-light border text-secondary ms-2" data-tip={t('ehr:prescription_anonymous_tip', 'Genera recetas anonimas. Ejemplo para pacientes con HIV')}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                checked={!!state.tags?.anonymous}
                id="prescriptionAnonymous"
                onChange={() => setState({ ...state, tags: { ...state.tags, anonymous: !state.tags?.anonymous } })}
              />
              <label className="form-check-label ms-1" htmlFor="prescriptionAnonymous">
                {t('ehr:prescription_anonymous', 'HIV')}
              </label>
            </div>
          </div>

          <div className="d-flex gap-2 justify-content-between">
            <button className="btn btn-sm btn-link" onClick={() => save()} disabled={mutation.isLoading || ((warnings.treatments && warnings.indications) || warnings.content)}>
              {!!mutation.isLoading && (
                <div className="spinner-border spinner-border-sm text-primary">
                  <span className="visually-hidden">{t("loading")}</span>
                </div>
              )} {t('ehr:create_prescription', 'Generar', { context: 'form' })}
            </button>
            {props.user?.isStaff && (
              <Link className="btn btn-sm text-secondary" to={new URLSearchParams(_location.search).get('preview') ? _location.pathname : `${_location.pathname}?preview=true`}>{t('preview', 'Preview')}</Link>
            )}
            <button className="btn btn-sm link-secondary" onClick={onClose}>{t('close')}</button>
          </div>

          {response?.status?.description && (
            <div className="text-center">
              <small className="text-danger me-2 fw-bold">
                {t('ehr:prescription_error', 'ERROR:', { context: 'form' })}
              </small>
              <small className="text-danger">
                {response.status?.description}
              </small>
            </div>
          )}

          {warnings.treatments && warnings.indications ? (
            <small className="text-danger ms-2 text-center">
              {t('ehr:prescription_warnings_missing_info_1', 'Debe agregar al menos un tratamiento o dar una indicacion', { context: 'form' })}
            </small>
          ) : warnings.content && (
            <small className="text-danger ms-2 text-center">
              {t('ehr:prescription_warnings_missing_info_2', 'Para generar la orden debe escribir el detalle de la misma', { context: 'form' })}
            </small>
          )}
        </div>
        {_location.search.includes('preview=true') && preview && (
          <div style={{ height: 857, width: 1132 }}>
            <iframe src={preview} className="w-100 h-100" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Prescription;
